$unit: 0.4rem;

$spacing-data: (
	none: 0,
	xxxs: $unit / 2, /* 2px*/
	xxs: $unit, /* 4px*/
	xs: $unit * 2, /* 8px*/
	sm: $unit * 4, /* 16px*/
	md: $unit * 6, /* 24px*/
	lg: $unit * 8, /* 32px*/
	xl: $unit * 12, /* 48px*/
	xxl: $unit * 16, /* 64px*/
	xxxl: $unit * 24 /* 96px*/
);

// Usage -- padding: spacing(xxs);
@function spacing($variant: base) {
	$value: map-get($spacing-data, $variant);

	@if $value == null {

		@error "Spacing variant `#{$variant}` not found.";
	}

	@return $value;
}

@mixin breakpoint($point) {

	@if $point == xxl {

		@media (max-width: 1699px) {

			@content;
		}
	} @else if $point == xl {

		@media (max-width: 1599px) {

			@content;
		}
	} @else if $point == lg {

		@media (max-width: 1399px) {

			@content;
		}
	} @else if $point == md {

		@media (max-width: 1199px) {

			@content;
		}
	} @else if $point == sm {

		@media (max-width: 991px) {

			@content;
		}
	} @else if $point == xs {

		@media (max-width: 767px) {

			@content;
		}
	} @else if $point == xxs {

		@media (max-width: 479px) {

			@content;
		}
	} @else if $point == minxxl {

		@media (min-width: 1700px) {

			@content;
		}
	} @else if $point == minxl {

		@media (min-width: 1600px) {

			@content;
		}
	} @else if $point == minlg {

		@media (min-width: 1400px) {

			@content;
		}
	} @else if $point == minmd {

		@media (min-width: 1200px) {

			@content;
		}
	} @else if $point == minsm {

		@media (min-width: 992px) {

			@content;
		}
	} @else if $point == minxs {

		@media (min-width: 768px) {

			@content;
		}
	} @else if $point == minxxs {

		@media (min-width: 480px) {

			@content;
		}
	}
}
