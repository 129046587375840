@import "variables"; @import "mixins";
@import "./00_base/variables";
@import "./00_base/mixins";

#hours.form-table {
	display: none !important;
}

.department-hours {
	overflow-x: auto;

	&__table {
		border-collapse: collapse;
		background-color: $color_white;
	}

	&__title-wrap {
		position: relative;
		border-right: 1px solid $color_light_grey_2;

		input {
			padding-right: spacing(md);
		}
	}

	&__heading {
		width: 12.5%;

		&:not(:last-child) {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 5px;
				right: -1px;
				bottom: 5px;
				width: 1px;
				background-color: $color_light_grey_2;
			}
		}

		&--title {
			width: 10%;
			padding: spacing(xs) spacing(sm);
		}
	}

	&__row {

		&:not(:last-child) {
			border-bottom: 1px solid $color_light_grey_2;
		}

		@keyframes row-appear {

			to {
				opacity: 1;
			}
		}
	}

	&__cell {
		position: relative;
		padding: spacing(sm) spacing(xs);

		&:not(:last-child) {
			border-right: 1px solid $color_light_grey_2 !important;
		}
	}

	&__input-wrap {
		position: relative;

		&:not(:first-child) {
			margin-top: spacing(xs);
		}
	}

	&__success-tooltip {
		position: absolute;
		top: -4rem;
		right: 10px;
		left: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: spacing(xxs) spacing(xs);
		background-color: $color_white;
		box-shadow: $shadow;
		pointer-events: none;
		transform: translateY(-2rem);
		opacity: 0;
		transition: transform 0.3s ease-out, opacity 0.2s;

		&::after {
			content: "";
			position: absolute;
			bottom: -0.4rem;
			left: calc(50% - 0.4rem);
			width: 0.8rem;
			height: 0.8rem;
			transform: rotate(45deg);
			transform-origin: center;
			background-color: $color_white;
		}

		span {
			color: var(--color-primary);
		}

		&.success {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__input {
		width: 100% !important;
		display: inline-block;
		font-size: 13px;
		box-sizing: border-box;
		background-color: $color_light_grey_2 !important;
		min-width: 80px;
	}

	&__delete-button {
		position: absolute;
		right: 1px;
		cursor: pointer;
		top: 50%;
		margin-top: -10px;
		color: $color_medium_grey_2;
	}

	.department-hours-placeholder {
		display: none;
		color: $color_medium_grey_1;

		&__content {
			background: $color_white;
			text-align: center;
			padding: spacing(md) 0;
		}

		&__icon {
			font-size: 6rem;
			width: auto;
			height: auto;
		}

		&__text {
			margin: 0;
			font-size: 2.4rem;
		}
	}
}
